<!--审核中-->
<template>
  <div>
    <div class="red-box">
      <p>
        药师审核中，审核通过后可购买
      </p>
    </div>
    <div class="page-box">
      <div class="card-box">
        <div class="dark-txt">
          电子处方编号
        </div>
        <div class="dark-txt mt40">
          {{ detail.cfh }}
        </div>
      </div>
      <div class="card-box">
        <div class="title">
          患者信息
        </div>
        <p class="desc">
          {{ detail.patientName }}  {{ detail.mobile }}
        </p>
        <div class="title mt40">
          诊断结果
        </div>
        <p class="desc">
          <span v-for="res in detail.diagnosisList" :key="res.zdbm">{{ res.zdmc }}</span>
        </p>
      </div>
      <div class="card-box">
        <div class="title">
          用药建议
        </div>
        <drugItem :data="detail.preDetails" />
      </div>
    </div>
    <!-- <div v-if="status=='2'" class="footer">
      <van-button type="info" round size="small" class="fixedBtn" @click="buy">
        立即购药
      </van-button>
    </div> -->
  </div>
</template>
<script>
import { userModel } from '@/api/user'
export default {
  name:'InReview',
  data(){
    return{
      detail:{}
    }
  },
  mounted(){
    this.getDetail()
  },
  methods:{
    getDetail(){
      let params={
        zjhm:this.$route.query.zjhm,
        prescriptionId:this.$route.query.id
      }
      userModel.prescriptionList(params).then(res=>{
          this.detail = res.data[0]
      })
    }
  }

}
</script>
<style scoped lang='less'>
 @import "../../../assets/styles/variable.less";
.red-box{
  width: 100%;
  height: 168px;
  background: #DC3030;
  p{
    color: #ffffff;
    margin: 0;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 88px;

  }
}
.page-box{
  padding: 0 32px 50px;
  box-sizing: border-box;
  position: relative;
  top: -82px;
  .card-box{
    width: 100%;
    background: #FFF;
    border-radius: 12px;
    padding: 40px 32px;
    margin-bottom: 24px;
    box-sizing: border-box;
    z-index: 2;
    .dark-txt{
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      color: @dark-font-color;
      line-height: 45px;
    }
    .title{
      font-size: 32px;
      font-weight: bold;
      color: @dark-font-color;
    }
    .desc{
      font-size: 28px;
      color: #666666;
      margin: 12px 0px;
    }
  }
}
.footer{
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  width:100%;
  height: 98px;
  background: #F2F2F2;
  text-align: right;
  padding: 16px 32px;
  box-sizing: border-box;

}
.fixedBtn{
  position: fixed;
  bottom: 15px;
  width: 686px;
  height: 68px;
  line-height: 68px;
  background: #3A8AE5;
  color: #ffffff;
  border-radius: 31px;
  margin: 0 auto;
}
</style>
